<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <Button label="Neues&nbsp;Gerät" icon="pi pi-plus" class="p-button-primary ml-1" @click="requestCreateDevice"></Button>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="data" :columns="mapColumns" :mapIcons="mapIcons" :empty="'Keine Geräte vorhanden'" :buttons="buttons" :key="trigger" :display-all-columns="false" :loading="loading" :fullscreen-table="false"></TableComponent>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import devicesService from "@/devices-service";
import TableComponent from "@/components/TableComponent";
import NewDeviceDialog from "@/components/NewDeviceDialog";

export default {
  extends: AuthView,
  name: 'MyDevicesView',
  components: {
    TableComponent
  },
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:0,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'Meine Geräte',url: '/my-devices'},
      ],
      mapColumns:{
        status:"Status",
        mac:"Mac Adresse",
        label:"Bezeichnung"
      },
      mapIcons:{
        status:{
          0:{
            icon:"pi pi-clock",
            title:"In Bearbeitung"
          },
          1:{
            icon:"pi pi-check text-green-600",
            title:"Bestätigt"
          },
          2:{
            icon:"pi pi-times text-red-600",
            title:"Abgelehnt"
          }
        }
      },
      userId:0,
      trigger:0,
      buttons:[{
        icon:"pi pi-trash",
        onClick:(args)=>{
          this.requestRemoveDevice(args);
        }
      }]
    }
  },
  async created() {
    await this.deferred;
    this.userId = (this.authService.getLocalProfile()||{}).id;
    await this.loadData();
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await devicesService.get(this.userId);
      this.data = data.map(item=>({...item,status:`${item.status}${item.reason}`}));
      console.log(data);
    },
    requestRemoveDevice(args) {
      this.$confirm.require({
        acceptClass:"p-button",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Gerät löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await devicesService.delete(args.id);
            this.$toast.add({severity:'warn', summary: 'Gerät löschen', detail:"Gerät gelöscht.", life: 3000});
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    requestCreateDevice() {
      this.$dialog.open(NewDeviceDialog, {
        props: {
          header: 'Neue Gerät',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '50vw',
            '640px': '80vw',
            '460px': '100vw'
          },
        },
        onClose: async (options) => {
          const data = options.data;
          if (data) {
            try {
              await devicesService.create({...data,userId:this.userId});
              this.$toast.add({severity:'success', summary: 'Neues Gerät', detail:"Gerät erfolgreich hinzugefügt.", life: 3000});
              this.loadData();
            } catch (err) {
              const response = err.response?.data||{};
              this.$toast.add({severity:'error', summary: `Fehler ${response.status}`, detail:`${response.error}`, life: 3000})
            }
          }
        }
      });
    },
  }
}
</script>
