<template>
  <!-- display sidebar if current route is a descendant of a tab root -->
  <cf-container v-if="tabs.filter(tab=>$route.path.match(new RegExp('^'+tab.root))).length" :class="$route.path.match(/^\/home/) ? 'bg-home' : ''">
    <cf-sidebar class="active flex flex-column">
      <TabMenu :model="filteredTabs" @tab-change="onTabChange" :active-index="activeIndex" :class="'main-menu flex-grow-1'">
      </TabMenu>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a target="_blank" class="p-menuitem-link" role="presentation" href="/dokumentation/hilfe/">
              <span class="p-menuitem-icon pi pi-question-circle"></span><span class="p-menuitem-text">Hilfe</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a class="p-menuitem-link" role="presentation" @click.prevent="logout" v-if="localProfile">
              <span class="p-menuitem-icon pi pi-sign-out"></span><span class="p-menuitem-text">Abmelden</span>
            </a>
            <a class="p-menuitem-link" role="presentation" @click.prevent="login" v-else>
              <span class="p-menuitem-icon pi pi-sign-in"></span><span class="p-menuitem-text">Anmelden</span>
            </a>
          </li>
        </ul>
      </div>
    </cf-sidebar>
    <cf-content>
      <router-view></router-view>
    </cf-content>
  </cf-container>
  <router-view v-else></router-view>
  <ConfirmDialog></ConfirmDialog>
  <DynamicDialog></DynamicDialog>
  <Toast></Toast>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      tabs: [
        {label: 'Start', root:"/home", icon:"pi pi-home"},
        {label: 'Meine Geräte', root:"/my-devices", icon:"pi pi-sitemap", userlevel: 0},
        {label: 'System', root: '/system', icon:"pi pi-bolt", userlevel: 2}
      ],
      activeIndex:0,
      sidebarVisible:true,
      localProfile:null,
    }
  },
  watch:{
    $route (){
      this.onRouteChange(this.$route.path);
    }
  },
  mounted() {
    this.onRouteChange(this.$route.path);
    let debounceSwitch = 200;
    let lastScrollTop = 0;
    let lastSwitchTime = 0;
    window.addEventListener('scroll', () => {
      let node = document.querySelectorAll("#app .p-datatable-header");
      node = node && node.length ? node[node.length-1] : node;
      let now = new Date().getTime();
      if (now-lastSwitchTime>debounceSwitch) {
        lastSwitchTime = now;
        let scrollTop = document.scrollingElement.scrollTop;
        let up = scrollTop<lastScrollTop;
        if (up)
          node.classList.remove("stick-off");
        else if (scrollTop>500)
          node.classList.add("stick-off");
        lastScrollTop = scrollTop;
      }
    });
    function refreshVars() {
      let sidebar = document.querySelector("cf-sidebar");
      if (sidebar)
        document.documentElement.style.setProperty('--sidebar-width',`${sidebar.clientWidth}px`);
    }
    window.addEventListener('resize', () => {
      refreshVars()
    });
    // needed for reactivity (filteredTabs)
    window.addEventListener('auth-change',()=>{
      this.localProfile = this.authService.getLocalProfile();
    });
  },
  async created() {
    try {
      this.localProfile = await this.authService.getProfile();
    } catch (err) {
      this.localProfile = null;
    }
  },
  methods: {
    onTabChange(e) {
      this.$router.push(this.activeIndex===e.index ? this.tabs[e.index].root  : this.tabs[e.index].path || this.tabs[e.index].root)
    },
    onRouteChange(currentPath) {
      let i=0;
      while (i<this.tabs.length && !currentPath.match(new RegExp("^"+this.tabs[i].root))) {
        i++;
      }
      if (i<this.tabs.length) {
        this.tabs[i].path = currentPath;
        this.activeIndex = i;
      }
    },
    async logout() {
      await this.authService.logout();
      this.login();
    },
    login() {
      this.$router.push("/login");
    }
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter(tab=>(tab.userlevel||-1)<=(this.localProfile ? this.localProfile.level||-1 : -1));
    }
  }
}
</script>