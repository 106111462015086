<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between p-button-success pr-2">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full opacity-0">
        <Button></Button>
      </div>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="flex flex-column mt-2">
      <div v-for="(value,name) in data" v-bind:key="name" >
        <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2" v-if="mapColumns[name]">
          <label :for="name" class="w-2 flex-shrink-0">{{mapColumns[name]||name}}</label>
          <Dropdown v-if="mapColumnEnumKey[name]" v-model="data[name]" :options="mapEnum[mapColumnEnumKey[name]]" optionLabel="name" optionValue="id" placeholder="Wählen" @change="onChangeData" />
          <InputText v-else :id="name" type="text" v-model="data[name]" class="flex-grow-1" @input="onChangeData" />
        </div>
      </div>
      <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2">
        <label class="w-2 flex-shrink-0">Bearbeiter</label>
        <Dropdown v-model="data['isEditor']" :options="mapEnum['no_yes']" optionLabel="name" optionValue="id" placeholder="Wählen" @change="onChangeEditor" />
      </div>
      <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2">
        <label class="w-2 flex-shrink-0">Aktiv</label>
        <Dropdown v-model="data['isActive']" :options="mapEnum['no_yes']" optionLabel="name" optionValue="id" placeholder="Wählen" @change="onChangeEditor" />
      </div>
    </div>
    <div class="flex flex-row mt-2">
      <Button class="p-button-primary" label="Passwort" icon="pi pi-key" @click="requestSetPassword"></Button>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import usersService from "@/users-service";
import PasswordDialog from "@/components/PasswordDialog";

export default {
  extends: AuthView,
  name: 'UserView',
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:2,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'System',url: '/system'},
        {label: 'Benutzer',url: '/system/users'},
        {label: ''}
      ],
      mapColumns:{
        username:"Benutzername",
        email:"Email",
        level:"Userlevel",
        confirmation:"Freischaltung",
        expired:"Abgelaufen"
      },
      mapEditorColumns:{

      },
      listEnumKeys: [],
      mapEnum: {
        level:[{id:0,name:"Benutzer"},{id:1,name:"Admin"},{id:2,name:"Superuser"}],
        no_yes:[{id:0,name:"nein"},{id:1,name:"ja"}],
      },
      mapColumnEnumKey: {
        level:"level",
        confirmation:"no_yes",
        expired:"no_yes"
      },
      savedState:{},
      listFiles:[],
      uploadIcon:"pi pi-upload",
      mergeIcon:"pi pi-database"
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
    this.listCrumbs[this.listCrumbs.length-1].label = this.data ? `#${this.data.id}` : "";
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await usersService.get(this.id);
      this.data = data;
    },
    async onChangeData() {
      try {
        await usersService.update(this.id,this.data);
      }
      catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
        this.loadData();
      }
    },
    async setPassword(pass) {
      try {
        await usersService.update(this.id, {pass});
        this.$toast.add({severity:'success', summary: 'Passwortänderung', detail:"Passwort erfolgreich geändert", life: 3000})
      }
      catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
        this.loadData();
      }
    },
    requestSetPassword() {
      this.$dialog.open(PasswordDialog, {
        props: {
          header: 'Passwort setzen',
          modal: true,
          style: {
            width: '50vw',
          },
          breakpoints:{
            '960px': '50vw',
            '640px': '80vw',
            '460px': '100vw'
          },
        },
        onClose: (options) => {
          const data = options.data;
          if (data) {
            this.setPassword(data.val)
          }
        }
      });
    },
    onChangeEditor() {
      usersService.update(this.id,this.data)
    }
  }
}
</script>
