<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="data" :columns="mapColumns" :mapIcons="mapIcons" :empty="'Keine Geräte vorhanden'" :buttons="buttons" :key="trigger" :display-all-columns="false" :loading="loading" :fullscreen-table="false"></TableComponent>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import devicesService from "@/devices-service";
import TableComponent from "@/components/TableComponent";

export default {
  extends: AuthView,
  name: 'DevicesView',
  components: {
    TableComponent
  },
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:2,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'System',url: '/system'},
        {label: 'Geräte',url: '/system/devices'}
      ],
      mapColumns:{
        status:"Status",
        mac:"Mac Adresse",
        username:"Benutzer",
        label:"Bezeichnung"
      },
      mapIcons:{
        status:{
          0:{
            icon:"pi pi-clock",
            title:"In Bearbeitung"
          },
          1:{
            icon:"pi pi-check text-green-600",
            title:"Bestätigt"
          },
          2:{
            icon:"pi pi-times text-red-600",
            title:"Abgelehnt"
          }
        }
      },
      userId:0,
      trigger:0,
      buttons:[{
        icon:"pi pi-arrow-right",
        onClick:(args)=>{
          this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
        }
      }]
    }
  },
  async created() {
    await this.deferred;
    this.userId = (this.authService.getLocalProfile()||{}).id;
    await this.loadData();
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await devicesService.get(0);
      this.data = data;
    },
  }
}
</script>
