<template>
  <div class="flex justify-content-center align-items-center flex-grow-1">
    <div class="px-2 lg:px-4 shadow-0 lg:shadow-2 border-black-alpha-30 m-0 md:m-3 lg:w-9 xl:w-6 bg-white-alpha-90">
      <div class="text-center mb-3 mt-3">
        <div class="flex flex-row jusify-content-start align-items-end mb-1">
          <div class="flex-1">&nbsp;</div>
          <img src="../assets/losla-web-1.png" class="w-6 md:w-3 flex-1 cursor-pointer" @click="goto()">
          <div class="flex-1 text-900 text-xl md:text-3xl font-medium flex align-items-end line-height-1">NAC</div>
        </div>
        <div v-if="!userId" class="mt-5 font-medium text-sm md:text-base line-height-2 md:line-height-3">
          Um Ihre Geräte zu erfassen, <span class="text-primary font-bold cursor-pointer" @click="goto('/login')">melden Sie sich bitte an</span>.
        </div>
        <div v-else class="flex flex-grow-1 justify-content-center mt-3 md:mt-5 font-medium text-sm md:text-base line-height-2 md:line-height-3">
          <ul class="flex flex-column align-items-start text-left">
            <li>Um Ihre Geräte zu erfassen, klicken Sie <span class="text-primary font-bold cursor-pointer" @click="goto('/my-devices')">hier</span>.</li>
            <li class="mt-2">Hilfe bei der Ermittlung Ihrer Geräte-Daten erhalten Sie <a href="/dokumentation/hilfe/" target="_blank" class="no-underline text-primary font-bold">hier</a>.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  data() {
    return {
      userId:0
    }
  },
  created() {
    this.userId = (this.authService.getLocalProfile()||{}).id;
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul>li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
