<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between p-button-success pr-2">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full opacity-0">
        <Button></Button>
      </div>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <div class="flex flex-column mt-2">
      <div v-for="(value,name) in data" v-bind:key="name" >
        <div class="flex flex-column lg:flex-row justify-content-start lg:align-items-center p-2" v-if="mapColumns[name]">
          <label :for="name" class="w-2 flex-shrink-0">{{mapColumns[name]||name}}</label>
          <Dropdown v-if="mapColumnEnumKey[name]" v-model="data[name]" :options="mapEnum[mapColumnEnumKey[name]]" optionLabel="name" optionValue="id" placeholder="Wählen" @change="onChangeData(name)" />
          <InputText v-else :id="name" type="text" v-model="data[name]" class="flex-grow-1" @input="onChangeData(name)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import devicesAdminService from "@/devices-admin-service";

export default {
  extends: AuthView,
  name: 'DeviceView',
  props: {
    id:[Number, String]
  },
  data() {
    return {
      requiredUserLevel:2,
      data: {},
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'System',url: '/system'},
        {label: 'Geräte',url: '/system/devices'},
        {label: ''}
      ],
      mapColumns:{
        status:"Status",
        reason:"Kommentar",
      },
      mapEditorColumns:{

      },
      listEnumKeys: [],
      mapEnum: {
        status:[{id:0,name:"in Bearbeitung"},{id:1,name:"Bestätigt"},{id:2,name:"Abgelehnt"}],
        no_yes:[{id:0,name:"nein"},{id:1,name:"ja"}],
      },
      mapColumnEnumKey: {
        status:"status",
      },
      savedState:{},
      listFiles:[],
      uploadIcon:"pi pi-upload",
      mergeIcon:"pi pi-database"
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
    console.log(this.data);
    this.listCrumbs[this.listCrumbs.length-1].label = this.data ? `#${this.data.id}` : "";
    this.loading = false;
  },
  methods: {
    async loadData() {
      let {data} = await devicesAdminService.get(this.id);
      this.data = data;
      console.log(data);
    },
    async onChangeData(key) {
      try {
        await devicesAdminService.update(this.id,{key,val:this.data[key]});
      }
      catch (err) {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
        this.loadData();
      }
    },
    onChangeEditor(key) {
      devicesAdminService.update(this.id,{key,val:this.data[key]});
    }
  }
}
</script>
