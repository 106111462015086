<template>
  <div class="p-fluid grid formgrid">
    <div class="field col-12">
      <label>MAC-Adresse:</label>
      <InputText type="text" v-model="mac" class="flex-grow-1" placeholder="30:2c:61:15:83:04" autofocus/>
    </div>
    <div class="field col-12">
      <label>Bezeichnung:</label>
      <InputText type="text" v-model="label" placeholder="z.B. Mobiltelefon Max" class="flex-grow-1" />
    </div>
  </div>
  <div class="flex flex-row mt-4 justify-content-end w-full">
    <Button label="Abbrechen" icon="pi pi-times" @click="closeDialog(false)" class="p-button-text mr-2"/>
    <Button :disabled="!mac.length && !label.length" label="Ok" icon="pi pi-check" @click="requestCloseDialog" autofocus />
  </div>
</template>

<script>

export default {
  inject: ['dialogRef'],
  name: "PasswordDialog",
  data() {
    return {
      mac:"",
      label:""
    }
  },
  async created() {
    let params = this.dialogRef.data;
    if (params) {
      this.val = params.val;
    }
  },
  methods: {
    closeDialog(ok=true) {
      this.dialogRef.close(ok ? {
        mac:this.mac,
        label:this.label
      } : null);
    },
    isValid() {
      return this.mac.match(/[0-9a-fA-F]{2}[:-][0-9a-fA-F]{2}[:-][0-9a-fA-F]{2}[:-][0-9a-fA-F]{2}[:-][0-9a-fA-F]{2}[:-][0-9a-fA-F]{2}/) && this.label.length>1
    },
    requestCloseDialog() {
      if (this.isValid())
        this.closeDialog();
      else {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:"Ihre Eingaben sind nicht korrekt", life: 3000})
      }
    }
  }
}
</script>

<style scoped>

</style>