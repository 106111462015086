<template>
  <form v-if="ensured" class="flex-grow-1 flex flex-colum justify-content-center align-items-center bg-login" ref="loginForm" autocomplete="on" v-on:submit.prevent>
    <div class="p-4 border-black-alpha-50 w-full lg:w-6 bg-white-alpha-90 shadow-2">
      <div class="text-center mb-3">
        <div class="flex flex-row jusify-content-start align-items-end mb-1">
          <div class="flex-1">&nbsp;</div>
          <img src="../assets/losla-web-1.png" class="w-3 flex-1 cursor-pointer" @click="goto()">
          <div class="flex-1 text-900 text-3xl font-medium flex align-items-end line-height-1">NAC</div>
        </div>
        <span class="font-medium line-height-3">Bei Ihrem Konto anmelden</span>
      </div>

      <div>
        <label for="username" class="block text-900 font-medium mb-2">Benutzername</label>
        <InputText id="username" type="text" class="w-full mb-3" v-model="username" autocomplete="username" name="username" required />

        <label for="password" class="block text-900 font-medium mb-2">Passwort</label>
        <InputText id="password" type="password" class="w-full mb-3" v-model="password" autocomplete="password" name="password" required v-on:keyup.enter="login" />

        <div class="flex align-items-center justify-content-between">
          <a class="font-medium no-underline text-blue-500 cursor-pointer" href="/reset">Passwort vergessen?</a>
          <Button label="Anmelden" icon="pi pi-sign-in" class="flex-shrink-0" @click="login"></Button>
        </div>
      </div>
    </div>
  </form>
  <Toast></Toast>
</template>

<script>

export default {
  name: 'LoginView',
  components: {
  },
  data() {
    return {
      username:"",
      password:"",
      target:"",
      ensured:false
    }
  },
  async created() {
    this.target = this.$route.query?.q;
    try {
      if (await this.authService.ensureAuth())
        this.goto(this.target);
    }
    catch (err) {
      this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
    }
    this.ensured = true;
  },
  methods: {
    goto(path="/") {
      this.$router.push(path);
    },
    clear() {
      this.username="";
      this.password="";
    },
    async login() {
      if (this.username && this.password) {
        try {
          await this.authService.login({username:this.username,password:this.password});
          await this.authService.ensureAuth();
          this.goto(this.target);
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Login Fehler', detail:err.response ? `${err.response.status} ${err.response.statusText}` : err, life: 3000})
          this.clear();
        }
      }
    },
  }
}
</script>