import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import LoginView from "@/components/LoginView";
import HomeView from "@/components/HomeView";
import AdminView from "@/components/AdminView";
import UsersView from "@/components/UsersView";
import SystemView from "@/components/SystemView";
import UserView from "@/components/UserView";
import SettingsView from "@/components/SettingsView";
import ResetView from "@/components/ResetView";
import ResetRequestView from "@/components/ResetRequestView";
import MyDevicesView from "@/components/MyDevicesView";
import PasswordExpiredView from "@/components/PasswordExpiredView";
import DevicesView from "@/components/DevicesView";
import DeviceView from "@/components/DeviceView";

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: HomeView
    },
    {
        path: '/my-devices',
        component: MyDevicesView
    },
    {
        path: '/admin',
        component: AdminView
    },
    {
        path: '/system',
        component: SystemView
    },
    {
        path: '/system/users',
        component: UsersView
    },
    {
        path: '/system/users/:id',
        component: UserView,
        props: true
    },
    {
        path: '/system/devices',
        component: DevicesView
    },
    {
        path: '/system/devices/:id',
        component: DeviceView,
        props: true
    },
    {
        path: '/system/config',
        component: SettingsView
    },
    {
        path: '/Login',
        component: LoginView
    },
    {
        path: '/reset',
        component: ResetRequestView
    },
    {
        path: '/reset/:uuid',
        component: ResetView,
        props: true
    },
    {
        path: '/password-expired/',
        component: PasswordExpiredView,
    },
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
