<template>
  <div class="p-datatable">
    <div class="p-datatable-header border-top-none flex flex-row justify-content-between">
      <Breadcrumb :home="home" :model="listCrumbs" class="p-0 flex">
        <template #item="{item}">
          <span @click="$router.push(item.url||'')"><i :class="item.icon"></i>{{item.label}}</span>
        </template>
      </Breadcrumb>
      <div class="flex flex-column justify-content-center flex-grow-1 align-items-end max-w-full">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="searchValue" placeholder="Suche" @keyup.enter="trigger++" class="search"/>
          <i class="pi pi-times right-0 mr-2 cursor-pointer" v-if="searchValue" @click="searchValue=''; trigger++"/>
        </span>
      </div>
      <Button label="Neuer&nbsp;Benutzer" icon="pi pi-plus" class="p-button-primary ml-1" @click="createUser"></Button>
    </div>
  </div>
  <div :class="`deferred-content${loading ? ' loading' : ''}`">
    <TableComponent :rows="listUsers" :columns="mapColumns" :map-enums="mapEnums" v-model:selected="selectedUsers" :key="trigger"
        @selectionChange="onSelectionChange" selection-mode="none" :buttons="buttons" :display-all-columns="false" :loading="loading" :fullscreen-table="false" :search="searchValue"
        ></TableComponent>
  </div>
</template>

<script>
import AuthView from "@/components/AuthView";
import usersService from "@/users-service";
import TableComponent from "@/components/TableComponent";

export default {
  extends: AuthView,
  name: 'UsersView',
  components: {
    TableComponent
  },
  data() {
    return {
      requiredUserLevel:2,
      listUsers:[],
      selectedUsers:[],
      mapColumns:{
        id:"id",
        username:"Benutzername",
        email:"Email",
        level:"Userlevel",
      },
      buttons:[{
        icon:"pi pi-trash",
        onClick:(args)=>{
          this.requestRemoveUser(args);
        }
      },{
        icon:"pi pi-arrow-right",
        onClick:(args)=>{
          this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${args.id}`);
        }
      }],
      loading:true,
      home: {icon: 'pi pi-home', url: '/'},
      listCrumbs: [
        {label: 'System',url: '/system'},
        {label: 'Benutzer',url: '/system/users'}
      ],
      searchValue:"",
      trigger:0,
      mapEnums:{
        level:{
          0:"User",
          1:"Admin",
          2:"Superuser"
        },
        isActiveEditor: {
          0:"nein",
          1:"ja"
        }
      }
    }
  },
  async created() {
    await this.deferred;
    await this.loadData();
    this.trigger++;
    this.loading = false;
  },
  methods: {
    async loadData() {
      this.listUsers = await this.getUsers();
    },
    async getUsers() {
      let {data} = await usersService.index();
      return data;
    },
    onSelectionChange(selectedUsers) {
      console.log(selectedUsers,this.selectedUsers);
    },
    async createUser() {
      let {data} = await usersService.create({username:"default"});
      this.$router.push(`${this.$route.path.replace(/\/$/,"")}/${data.insertId}/`);
    },
    requestRemoveUser(args) {
      this.$confirm.require({
        acceptClass:"p-button-danger",
        acceptIcon:"pi pi-exclamation-triangle text-xl",
        rejectClass:"p-button-text p-button-plain",
        acceptLabel:"Ja",
        rejectLabel:"Nein",
        message: `Sicher?`,
        header: 'Benutzer löschen',
        icon: 'pi pi-question-circle',
        accept: async () => {
          try {
            await usersService.delete(args.id);
          } catch (err) {
            this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
          }
          this.loadData();
        }
      });
    },
    async onRowReordered({value:inAscendingOrder}) {
      await usersService.sort({order:inAscendingOrder.map(item=>item.id)});
      this.loadData();
    }
  }
}
</script>
